import {
  Divider as BaseDivider,
  DividerProps as MuiDividerProps,
  SxProps,
  styled,
} from '@mui/material';
import cls from 'classnames';
import { merge } from 'lodash';

import { UtilityProps } from '../../utils/prop';

export enum DividerVariant {
  HARD = 'HARD',
  SOFT = 'SOFT',
}

type BaseProps = {
  /** Variant of divider */
  variant?: `${DividerVariant}` | DividerVariant;
};

const classes = {
  root: 'Divider--root',
  hard: 'Divider--hard',
  soft: 'Divider--soft',
};

export type DividerProps = Omit<MuiDividerProps, keyof BaseProps> & BaseProps & UtilityProps;

/** This component inherits [MUI Divider's API](https://mui.com/material-ui/api/divider/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-divider-props--documentation)
 */
export const Divider = styled(
  ({ variant = DividerVariant.SOFT, className, ...props }: DividerProps) => {
    return (
      <BaseDivider
        {...merge(
          {
            className: cls(
              className,
              classes.root,
              variant === 'HARD' && classes.hard,
              variant === 'SOFT' && classes.soft,
            ),
          } satisfies DividerProps,
          props,
        )}
      />
    );
  },
)(
  ({ theme: { palette } }) =>
    ({
      [`&.${classes.soft}`]: {
        borderColor: palette.$grey.$200,
      },

      [`&.${classes.hard}`]: {
        borderColor: palette.$grey.$600,
      },
    }) satisfies SxProps,
);
